<template>
    <div class="row">
        <div class="col-12">
            <Widget class="h-auto">
                <template #title>Inscripción a Torneos</template>
                <div class="contenedor-selectores">
                    <FormSelect @selectChange="get_calendarios" class="selector" label="Evento al que se inscribe"
                        :options="torneos" icon="fa-solid fa-trophy" />

                    <FormSelect @selectChange="updateCalendarios" class="selector" label="Nombre de la fecha"
                        :options="calendarios" icon="fa-regular fa-calendar" />
                </div>
                <vue-table class="marg-b-2" v-if="inscribirTable.headers" :values="perros" :headers="inscribirTable.headers"
                    :options="inscribirTable.options" :actions="inscribirTable.actions" ref="vtable"
                    :search_placeholder="'Buscar por codigo'" @inscripcion="inscribir" @changed="findPerros"
                    :search_min_length="5" :spinnerClass="'darkSpinner'" 
                    :isFetching="isFetchingInscribir"
                    />

                <h5>Ejemplares inscriptos</h5>
                <vue-table class="marg-b-2" v-if="inscriptosTable.headers" :values="perros_inscriptos"
                    :headers="inscriptosTable.headers" :options="inscribirTable.options" ref="vtable"
                    :search_placeholder="'Buscar perro por codigo'" @changed="findInscriptos" 
                    :isFetching="isFetchingInscriptos"
                    />
            </Widget>
        </div>
    </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import FormSelect from "/src/components/Inscripciones/FormSelect.vue";
import { mapGetters } from "vuex";

export default {
    components: {
        Widget,
        FormSelect
    },
    data() {
        return {
            inscribirTable: {
                options: {
                    "tbodyClass": "h-3"
                },
                actions: [
                ],
                headers: [
                    {
                        title: "can_nombre",
                        mask: "Can Nombre",
                        sortable: true,
                        searchable: true
                    },
                    {
                        title: "can_codigo",
                        mask: "Can Numero",
                        sortable: true,
                        searchable: true

                    },
                    {
                        title: "can_sexo",
                        mask: "Can Sexo",
                        sortable: true,
                    },
                    {
                        title: "fecha_nacimiento",
                        mask: "Fecha de Nacimiento",
                        sortable: true,
                    },
                    {
                        title: "can_peludo",
                        mask: "Pelo Largo",
                        sortable: true,
                    },
                    {
                        title: "duenio_nombre",
                        mask: "Dueño",
                        sortable: true,
                        searchable: false
                    },
                    {
                        title: "duenio_agrupacion",
                        mask: "Agrupacion",
                        sortable: true,
                    },
                    {
                        title: "duenio_delegacion",
                        mask: "Delegacion",
                        sortable: true,
                    },
                    {
                        title: "duenio_estado_cliente",
                        mask: "Socio",
                        sortable: true,
                    },
                    {
                        title: "inscribir",
                        mask: "Acciones",
                        sortable: false,
                        html_insert: {
                            "tag": "inscribir_label"
                        },
                        actions: {
                            "inscribir_label": this.inscribir,
                        }
                    },
                ],
                search_options: [

                ]
            },
            inscriptosTable: {
                options: {
                    /// Go to OPTIONS SECTION for explanation
                },
                actions: [
                ],
                headers: [
                    {
                        title: "can_nombre",
                        mask: "Can Nombre",
                        sortable: true,
                        searchable: false
                    },
                    {
                        title: "can_codigo",
                        mask: "Can Numero",
                        sortable: true,
                        searchable: true

                    },
                    {
                        title: "can_sexo",
                        mask: "Can Sexo",
                        sortable: true,
                    },
                    {
                        title: "fecha_nacimiento",
                        mask: "Fecha de Nacimiento",
                        sortable: true,
                    },
                    {
                        title: "can_categoria",
                        mask: "Categoria",
                        sortable: false,
                    },
                    {
                        title: "can_peludo",
                        mask: "Pelo Largo",
                        sortable: true,
                    },
                    {
                        title: "duenio",
                        mask: "Dueño",
                        sortable: true,
                        searchable: false
                    },
                    {
                        title: "agrupacion",
                        mask: "Agrupacion",
                        sortable: true,
                    },
                    {
                        title: "delegacion",
                        mask: "Delegacion",
                        sortable: true,
                    },
                    {
                        title: "torneo",
                        mask: "Torneo",
                        sortable: true,
                    },
                    {
                        title: "fecha",
                        mask: "Fecha",
                        sortable: false,
                    },

                ]
            },
            torneo: null,
            calendario_id: null,
            isFetchingInscribir: false,
            isFetchingInscriptos: false,
        }
    },

    methods: {
        async prepareFilters() {
            await this.$store.dispatch('findTorneos');
            this.torneo = this.getTorneos[0].value;

            await this.$store.dispatch('findCalendarios', this.torneo);
            this.calendario_id = this.getCalendarios[0].value ??= null;
        },
        async get_calendarios(arg) {
            this.torneo = arg;
            await this.$store.dispatch('findCalendarios', arg);
            this.calendario_id = this.getCalendarios[0].value ??= null;
        },
        async updateCalendarios(arg) {
            this.calendario_id = arg;
        },
        async findPerros(data) {
            await this.$store.dispatch('clearPerros');
            this.isFetchingInscribir = true;
            await this.$store.dispatch('findPerros', { data: data, user: this.user, calendario: this.calendario_id });
            this.isFetchingInscribir = false;
        },
        async findInscriptos(data) {
            await this.$store.dispatch('clearInscriptos');
            this.isFetchingInscriptos = true;
            await this.$store.dispatch('findInscriptos', { data: data, user: this.user });
            this.isFetchingInscriptos = false;
        },
        async inscribir(data) {
            if (data.inscribir) {
                data.user = this.user;
                this.$store.dispatch('inscribirCan', data);
            }
        },
    },
    computed: {
        ...mapGetters(['user', 'getTorneos', 'getCalendarios', 'getPerros', 'getUserInfo']),
        torneos() {
            return this.getTorneos;
        },
        calendarios() {
            return this.getCalendarios;
        },
        perros() {
            return this.$store.getters.getPerros;
        },
        perros_inscriptos() {
            return this.$store.getters.getInscriptos;
        },
    },
    async mounted() {
        this.prepareFilters();
    }
}
</script>

<style>
.inscribir-button {
    padding: .5em;
    border-radius: 1.5em;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;

    transition: .3s;
}

.inscribir-button:hover {
    text-decoration: none;
    color: white;
    background-color: orange;
    border: 1px solid rgba(0, 0, 0, 0.0);
}
</style>